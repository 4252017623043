body {
    color: white;
    background-color: black;
}

a, a:visited {
    color: white;
}

#menu {
    background-color: #000000;
    height: 6rem;
}

#welcome {
    background-image: url('/public/img/background_city.png');
    background-repeat: no-repeat;
    background-position: center;
}

.background {
    background-repeat: no-repeat;
    background-position: center;
}

.tint {
    background: #000000cc;
}

.fade-in-section {
    opacity: 0;
    transform: translateY(20vh);
    visibility: hidden;
    transition: opacity 0.6s ease-out, transform 1s ease-out;
    will-change: opacity, visibility;
}

.fade-in-section.is-visible {
    opacity: 1;
    transform: none;
    visibility: visible;
}

.call-to-action {
    letter-spacing: .2rem;
}